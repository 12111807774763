import { FC, ReactNode, useState, useContext, createContext, useEffect } from 'react'
import axios from 'axios'
import { DigitsUnitType, User } from 'types/user'
import { Admin } from 'types/admin'
import { clients } from 'public/locales/ja/model/clients'
import { isUserCanViewPage } from 'utils/isUserCan'
import { sentrySetUser } from 'lib/sentry'
import { LoginPic } from 'types/pic'

type UserAction = 'view'

interface AuthContextType {
  isAuthorizing: boolean
  user: null | User
  setUser: (user: User | null) => void
  loginPic: null | LoginPic
  setLoginPic: (loginPic: LoginPic | null) => void
  admin: null | Admin
  setAdmin: (admin: Admin | null) => void
  cifLabel: string
  digitsUnit: DigitsUnitType
  userCan: (action: UserAction, page: string) => boolean
}
export const AuthContext = createContext<AuthContextType>({
  isAuthorizing: true,
  user: null,
  setUser: (user) => {
    console.log(user)
  },
  loginPic: null,
  setLoginPic: (loginPic) => {
    console.log(loginPic)
  },
  admin: null,
  setAdmin: (admin) => {
    console.log(admin)
  },
  cifLabel: '',
  digitsUnit: 0,
  userCan: (action: UserAction, page: string) => {
    console.log(action, page)
    return true
  },
})

export function useAuth(): AuthContextType {
  return useContext(AuthContext)
}

interface Props {
  children: ReactNode
}

export const AuthProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [isAuthorizing, setIsAuthorizing] = useState<boolean>(true)
  const [user, setUser] = useState<null | User>(null)
  const [loginPic, setLoginPic] = useState<null | LoginPic>(null)
  const [admin, setAdmin] = useState<null | Admin>(null)
  const [cifLabel, setCifLabel] = useState<string>('')
  const [digitsUnit, setDigitsUnit] = useState<DigitsUnitType>(0)

  useEffect(() => {
    const fetchUser = axios.get<User>('/api/auth/me').then((res) => {
      setUser(res.data)
      sentrySetUser(res.data.id, res.data.role)
    })

    const fetchLoginPic = axios.get<LoginPic>('/api/nndb/auth/me').then((res) => {
      setLoginPic(res.data)
    })

    Promise.any([fetchUser, fetchLoginPic])
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => setIsAuthorizing(false))
  }, [])

  useEffect(() => {
    if (!user) return
    setCifLabel(user?.banker?.bank?.cifLabel || clients.cif.label)
    setDigitsUnit((user?.banker?.bank?.digitsUnit || 0) as DigitsUnitType)
  }, [user])

  const userCan = (action: UserAction, page: string): boolean => {
    if (!user) return true

    if (action === 'view') {
      return isUserCanViewPage(page, user?.role)
    }
    return true
  }

  const value = { isAuthorizing, user, setUser, loginPic, setLoginPic, admin, setAdmin, cifLabel, userCan, digitsUnit }
  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  )
}
